<template>
  <div>
    <MyHeader :title="$t('lang.menu_patrolmissrecord')" :ShowBack="true" :BackUrl="backUrl" RightIcon="search"
      @rightclick="onRightClick" />

    <van-sticky :offset-top="46" v-show="showToolbar">
      <div class="divButton">
        <div class="divButton1">
          <van-button type="primary" size="small" @click="onCheckAll">{{ $t("lang.checkall") }}</van-button>&nbsp;
          <van-button type="info" size="small" @click="onToggleAll">{{ $t("lang.toggleall") }}</van-button>
        </div>
        <div class="divButton2">
          <van-button type="primary" size="small" @click="onShowDescr" v-show="showRemarkBtn">{{ $t("lang.batchremark") }}</van-button>&nbsp;
          <van-button type="danger" size="small" @click="onAudit" v-show="showAuditBtn">{{ $t("lang.batchaudit") }}</van-button>
        </div>
      </div>
    </van-sticky>

    <van-checkbox-group v-model="lstRid" ref="checkboxGroup">
      <van-cell-group>
        <van-pull-refresh v-model="loading" @refresh="onRefresh">

          <van-list v-model="loading" :finished="finished" :finished-text="$t('lang.listend')" :immediate-check="false"
            direction="down" @load="onLoad">
            <van-cell v-for="mData in lstData" :key="mData.id" :title="mData.placeName"
              :icon="mData.comment == '' ? '/img/audit1.png' : '/img/audit.png'"
              :title-class="mData.comment == '' ? '' : 'stated'"
              :label-class="mData.comment == '' ? '' : 'stated'"
              >
              <template slot="label">
                <span style="display: block">{{ $t("lang.arriveTime") }}：{{
                  mData.isMiss
                  ? "【" +
                  (mData.endTime > new Date()
                    ? $t("lang.notstarted")
                    : $t("lang.miss")) +
                  "】"
                  : mData.arriveTime != undefined
                    ? $Tools.FormatDate1(mData.arriveTime)
                    : ""
                }}</span>
                <span style="display: block">{{ $t("lang.router_user") }}：{{
                  mData.routeName +
                  (mData.inspectorName != undefined
                    ? " " + mData.inspectorName
                    : "")
                }}<br />
                  {{ $t("lang.startTime") }}：{{ $Tools.FormatDate1(mData.startTime)
                  }}<br />
                  {{ $t("lang.endTime") }}：{{
                    $Tools.FormatDate1(mData.endTime)
                  }}</span>
                <span style="display: block">{{ $t("lang.descriptions_miss") }}：{{ mData.comment }}</span>
              </template>
              <template #right-icon>
                <van-checkbox :name="mData.id" ref="checkboxes" :disabled="!showToolbar" />
              </template>
            </van-cell>
          </van-list>

        </van-pull-refresh>
      </van-cell-group>
    </van-checkbox-group>

    <!--div
      v-show="showHint"
      style="margin: 20px; text-align: center; display: block; color: #969799"
    >
      {{ $t("lang.nodata") }}
    </div-->

    <van-dialog v-model="showDescr" :title="$t('lang.missremark')" @confirm="onConfirm" show-cancel-button>
      <van-field v-model="fDescriptions" rows="4" autosize clearable type="textarea" :placeholder="$t('lang.missremark')" />
    </van-dialog>

  </div>
</template>

<style scoped>
.warning .van-cell__left-icon {
  color: #663366;
  font-size: 20px;
}

.warning .van-cell__title {
  color: #663366;
}

.isMiss .van-cell__left-icon {
  color: red;
  font-size: 20px;
}

.isMiss .van-cell__title {
  color: red;
}

.isWait .van-cell__left-icon {
  color: blue;
  font-size: 20px;
}

.isWait .van-cell__title {
  color: blue;
}

.divButton {
  display: flex;
  width: 100%;
  background-color: #33999960;
  padding: 5px;
  box-sizing: border-box;
}

.divButton1 {
  flex: 1;
}

.divButton2 {
  flex: 1;
  text-align: right;
}
.stated{
  color:#009a9a;
}
</style>

<script>
import { Dialog } from 'vant';
import MyHeader from "@/components/Header.vue";
import { GetPollingPlan, BatchRemarkPatrolPlanRecords, BatchAuditPatrolPlanRecords } from "@/api/index.js";
import { getSessionObj } from "@/utils/session.js";

export default {
  components: { MyHeader },
  data() {
    return {
      lstData: [],
      loading: false,
      finished: false,
      page: 1,
      backUrl: "/query/index",
      //showHint: true,

      showRemarkBtn: false,
      showAuditBtn: false,

      showDescr: false,
      fDescriptions: "",

      showToolbar: true,
      lstRid: [],
    };
  },

  mounted() {
    if (!this.$store.getters.getIsXunjianAPP)
      this.backUrl = "/query/index";
    else
      this.backUrl = "/query/appindex";

    let mPer = this.$store.getters.getPermission;
    this.showRemarkBtn = mPer == undefined ? false : mPer.isRemark;
    this.showAuditBtn = mPer == undefined ? false : mPer.isAudit;
  },

  activated() {
    //console.log("activated");
    if (this.$route.params.p) {
      if (this.$route.params.p.event == "export") this.Export();
      else {
        this.page = 1;
        this.$refs.checkboxGroup.toggleAll(false);
        this.lstData = [];
        this.Query();
      }
    }
  },

  deactivated() {
    //console.log("deactivated");
  },

  methods: {
    onRightClick() {
      this.$router.push({
        name: "QueryForm",
        params: {
          title: this.$t("lang.menu_patrolmissrecord"),
          f: "MissRecordAudit",
        },
      });
    },

    onRefresh() {
      this.page = 1;
      this.$refs.checkboxGroup.toggleAll(false);
      this.Query();
    },

    Query() {
      let p = null;
      if (
        this.$route.params.p != undefined &&
        this.$route.params.p.pagesize != 1
      )
        p = this.$route.params.p;
      if (!p) p = getSessionObj("Query_Params");
      if (!p) {
        // this.showHint = true;
        return;
      }
      p.mRecord = 0;

      this.showToolbar = p.mRecord_miss == 0 ? true : false;
      let that = this;
      that.loading = true;

      GetPollingPlan(
        {
          token: this.$store.getters.getToken,
          startTime: p.mDate1,
          endTime: p.mDate2,
          strCompanyIds: p.mCompany,
          strDepartmentIds: p.mDept,
          strRouteIds: p.mLine,
          strPlaceIds: p.mAddr,
          strGroupIds: p.mGroup,
          strInspectorIds: p.mUser,
          queryType: p.mRecord_miss,
          sort: p.mOrder,
          page: this.page,
          limit: p.pagesize,
        },
        (ret) => {
          that.loading = false;
          if (ret && ret.code == 0) {
            if (ret.count == 0) {
              that.finished = true;
              //that.showHint = false;
            } else {
              //填充
              if (this.page == 1) that.lstData = ret.data;
              else that.lstData.push(...ret.data);
              //this.showHint = that.lstData.length == 0;

              //判断是否全部加载完成
              if (that.lstData.length < ret.count) {
                that.page = that.page + 1;
                that.finished = false;
              } else {
                that.finished = true;
                //that.showHint = false;
              }
            }
          } else {
            that.finished = true;
            this.$Toast(!ret ? this.$t("lang.queryfail") : ret.message);
          }
        }
      );
    },

    onLoad() {
      this.Query();
    },

    onCheckAll() {
      this.$refs.checkboxGroup.toggleAll(true);
    },

    onToggleAll() {
      this.$refs.checkboxGroup.toggleAll();
    },

    onShowDescr() {
      if(this.lstRid.length==0){
        this.$Toast(this.$t("lang.hint_pleaseselect"));
        return;
      };
      this.showDescr = true;
    },

    onConfirm() {
      let strRid = this.lstRid.join();
      let objUser = this.$store.getters.getUser
      BatchRemarkPatrolPlanRecords(
        {
          token: this.$store.getters.getToken,
          strPPRIds: strRid,
          userId: objUser.userId,
          comment: this.fDescriptions.trim()
        },
        (ret) => {
          if (ret && ret.code == 0) {
            this.$Toast(this.$t("lang.hint_submit_success"));
            this.page = 1;
            this.Query();
          } else
            this.$Toast(!ret ? this.$t("lang.hint_submit_fail") : ret.message);
        })
    },

    onAudit() {
      if(this.lstRid.length==0){
        this.$Toast(this.$t("lang.hint_pleaseselect"));
        return;
      };
      let msg=this.$t("lang.auditconfirm1");
      for (let k = 0; k < this.lstData.length; k++) {
        let m = this.lstData[k];
        if (m.comment == "") {
          if (this.lstRid.indexOf(m.id) > -1) {
            msg=this.$t("lang.auditconfirm2");
            break;
          }
        }
      }

      Dialog.confirm({
        title: this.$t("lang.hint"),
        message: msg,
      })
        .then(() => {
          let strRid = this.lstRid.join();
          let objUser = this.$store.getters.getUser
          BatchAuditPatrolPlanRecords(
            {
              token: this.$store.getters.getToken,
              strPPRIds: strRid,
              userId: objUser.userId,
              comment: ""
            },
            (ret) => {
              if (ret && ret.code == 0) {
                this.page = 1;
                this.$Toast(this.$t("lang.hint_audit_success"));
                this.Query();
              } else
                this.$Toast(!ret ? this.$t("lang.hint_audit_fail") : ret.message);
            })
        })
        .catch(() => { });
    }
  },
};
</script>
